import Navbarngl from "../navbarngl";
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Card, Alert } from 'react-bootstrap';
import Tops from '../top';
import { createDefaultUser, handleLogin } from '../js/loginutils';

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    createDefaultUser();
  }, []);

  return (
    <div style={{ backgroundColor: 'white' }}>
      <Navbarngl />
      <h1 style={{ height: '80px', textAlign: 'center' }}>Login (Solo amministratori)</h1>
      <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
        <Row>
          <Col xs={12}>
            <Card style={{ width: '100%', maxWidth: '500px', padding: '1rem', margin: '0.5rem auto' }}>
              <Card.Body>
                <Form onSubmit={(e) => handleLogin(e, username, password, setError, navigate)}>
                  {error && <Alert variant="danger">{error}</Alert>}
                  <Form.Group controlId="formBasicUsername">
                    <Form.Label>Nome Utente</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Inserisci nome utente"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      style={{ fontSize: '1.2rem' }}
                    />
                  </Form.Group>

                  <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      style={{ fontSize: '1.2rem' }}
                    />
                  </Form.Group>

                  <Button variant="primary" type="submit" style={{ width: '100%', padding: '0.75rem', fontSize: '1.2rem' }}>
                    Accedi
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Tops />
    </div>
  );
};

export default Login;





  
   

 





