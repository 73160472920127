import React from "react";
import Navbarngl from "../navbarngl";
import Tops from "../top";
import { Container } from "react-bootstrap";
import Progettistudios from "../progettistudios";

function CilaStudios() {
    return(
 <div>
<Navbarngl />
<Container>
    <h1 style={{fontFamily: 'Inter, sans-serif', fontStyle: 'bold'}}>Cila Studios, il compartimento di programmazione, ricerche scientifiche e contenuti per i games marchiato Ngl Cila.</h1>
    <p style={{fontFamily: 'Inter, sans-serif'}}>Oltre ai semplici gossip, notizie e la pagina Instagram, NGL Cila sta cercando di crescere in altri contesti con dei suoi sottogruppi. Uno di questi è proprio Cilastudios, che si occupa oltre di affari interni a NGL (Ossia la programmazione del sito e del minigioco) anche di progetti esterni, che non hanno a che vedere con NGLCila, ma facente parte del loro gruppo</p>
    <Progettistudios />
</Container>
<Tops />
 </div>
    );
}

export default CilaStudios