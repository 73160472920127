// Home.js
import React, { useEffect } from 'react';
import { Container, Navbar, Button } from 'react-bootstrap';
import Navbarngl from '../navbarngl';
import Buttones from '../buttones';
import UserAccessData from '../geolocalizzazione';
import Navint from '../navint';
import Cardse from '../cardse';
import Ultimipost from '../ultimipost';
import Tops from '../top';
import Sidebar from '../infocomponent';
import Test from '../test';
import Partnership from '../partners';
import Navorario from '../navorario';
import ReactCarousel from '../CarousePhoto';
import ExploraNglVige from '../Esploranglvige';
import InstagramEmbed from '../instagramembed';


const Home = () => {

  return (
    <div className="home" style={{ 
      background: 'white', 
      color: 'white', 
    }}>
      <header>
        <Navbarngl />
        
      </header>
      <Navorario />
      
      
      
      <div style={{ }}>
        <Buttones />
        
        <UserAccessData />
      </div>
      
      <Navint />
      <Cardse />
      
      
      <ReactCarousel />

      
      
      <ExploraNglVige />
      <Partnership />
      <Ultimipost />
      
      
      
      <style jsx>{`
        .main-content {
          display: block;
          margin-top: 60px; /* Ensure content starts below the navbar */
        }
        @media (min-width: 768px) {
          .main-content {
            display: flex;
          }
        }
        .main-body {
          flex: 1;
          padding: 20px;
        }
      `}</style>
      <Tops />
    </div>
  );
}

export default Home;














