// components/NotFound.js
import React from 'react';
import Navbarngl from '../navbarngl';
import Tops from '../top';

function NotFound() {
    return (
        <div style={{backgroundColor: 'white'}}>
        <Navbarngl />
        <h1 style={{textAlign: 'center'}}>404, Pagina non trovata :(</h1>
        <p style={{textAlign: 'center'}}>Siamo spiacenti, questo link non è più disponibile o è inesistente. Prova con un altro URL</p>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        
        <Tops />
 
     </div>
    );
}

export default NotFound;
