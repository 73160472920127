import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDnl8ZvUpEyjClgwYSb5APcU24zY-X0H8U",
    authDomain: "ngl-cila.firebaseapp.com",
    projectId: "ngl-cila",
    storageBucket: "ngl-cila.appspot.com",
    messagingSenderId: "1049554300483",
    appId: "1:1049554300483:web:a023e58e8c5a8969cc6ef6",
    measurementId: "G-54W482ES88"
  };
  

  const app = initializeApp(firebaseConfig);
  export const functions = getFunctions(app);
  export const auth = getAuth(app);
  export const db = getFirestore(app);
