import React from "react";
import { NavbarToggle } from "react-bootstrap";
import Navbarngl from "../navbarngl";
import Tops from "../top";
import sfondo from '../contents/sfondo.png'

function People () {
    return(
    <div style={{backgroundImage:`url(${sfondo})`}}>
        
        <Navbarngl />
        <h1 style={{textAlign: 'center', color: 'white'}}>Le persone</h1>
        <Tops />
    </div>
    );
}

export default People;