import { db } from "./firebase";
import { collection, query, getDocs, addDoc, deleteDoc, doc, where } from "firebase/firestore";

export const fetchAccessData = async (setAccessData) => {
  const accessDataCollection = collection(db, "AccessData");
  const q = query(accessDataCollection);
  try {
    const querySnapshot = await getDocs(q);
    const results = querySnapshot.docs.map((doc) => doc.data());
    setAccessData(results);
  } catch (error) {
    console.error('Errore durante il recupero dei dati di accesso:', error);
  }
};

export const fetchBannedIPs = async (setBannedIPs) => {
  const bannedIPsCollection = collection(db, "BannedIPs");
  const q = query(bannedIPsCollection);
  try {
    const querySnapshot = await getDocs(q);
    const results = querySnapshot.docs.map((doc) => doc.data().ipAddress);
    setBannedIPs(results);
  } catch (error) {
    console.error('Errore durante il recupero degli IP bannati:', error);
  }
};

export const handleConfirmBan = async (
  currentIP,
  banReason,
  banDuration,
  banExpiryDate,
  setBannedIPs,
  bannedIPs,
  setShowModal,
  setBanReason,
  setBanDuration,
  setBanExpiryDate
) => {
  const bannedIPsCollection = collection(db, "BannedIPs");
  const bannedIP = {
    ipAddress: currentIP,
    reason: banReason,
    expiryDate: banDuration === 'temporary' ? banExpiryDate : null,
  };
  try {
    await addDoc(bannedIPsCollection, bannedIP);
    setBannedIPs([...bannedIPs, currentIP]);
    setShowModal(false);
    setBanReason('');
    setBanDuration('permanent');
    setBanExpiryDate(null);
  } catch (error) {
    console.error('Errore durante il ban dell\'IP:', error);
  }
};

export const handleUnban = async (ip, setBannedIPs, bannedIPs) => {
  const bannedIPsCollection = collection(db, "BannedIPs");
  const q = query(bannedIPsCollection, where("ipAddress", "==", ip));
  try {
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;
      await deleteDoc(docRef);
      setBannedIPs(bannedIPs.filter((bannedIP) => bannedIP !== ip));
    }
  } catch (error) {
    console.error('Errore durante lo sbannamento dell\'IP:', error);
  }
};

