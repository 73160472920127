import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import { Navbar as BootstrapNavbar } from 'react-bootstrap';
import styled from 'styled-components';

// Definisco la navbar utilizzando styled-components
const AnimatedNavbar = styled(BootstrapNavbar)`
  background-color: #000; /* Nero */
  font-family: 'Inter, sans-serif';

  .nav-link {
    color: white;
    font-size: 16px;
  }

  @media (min-width: 768px) {
    .nav-link {
      font-size: 20px;
    }
  }
`;

function Navint() {
    return (
        <div>
            <AnimatedNavbar>
                <Container>
                    <Nav className="me-auto">
                        <Nav.Link href="/people" aria-label="People" style={{fontFamily: 'Inter, sans-serif'}}>People</Nav.Link>
                        <Nav.Link href="/calcio" aria-label="Calcio" style={{fontFamily: 'Inter, sans-serif', fontStyle: 'bold'}}>Calcio</Nav.Link>
                        <Nav.Link href="/wiki" aria-label="NGLPop" style={{fontFamily: 'Inter, sans-serif', fontStyle: 'bold'}}>NGLPop!</Nav.Link>
                    </Nav>
                </Container>
            </AnimatedNavbar>
        </div>
    );
}

export default Navint;




