import React from "react";
import Navbarngl from "./navbarngl";
import Tops from "./top";
import sfondo from './contents/sfondo.png';
import Popcard from "./popcard";
import UserAccessData from "./geolocalizzazione";

function Wiki() {
    return (
        <div style={{ background: 'white' }}> {/* Cambia lo sfondo in bianco */}
            <Navbarngl />
            <UserAccessData />
            <h1 style={{ textAlign: 'center', color: 'black', fontFamily: 'Inter, sans-serif' }}>
                NGLPop, il canale ufficiale di NGL Cila, dove si scopre come funziona il mondo.
            </h1> {/* Cambia il colore del testo in nero */}
            <Popcard />
            <Tops />
        </div>
    );
}

export default Wiki;

