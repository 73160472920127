import React, { useState, useEffect } from "react";
import Navbarngl from "../navbarngl";
import Tops from "../top";
import UserAccessData from "../geolocalizzazione";
import { fetchPhotos } from '../js/photoutils';

function Archiviofoto() {
    const [photos, setPhotos] = useState([]);

    useEffect(() => {
        fetchPhotos(setPhotos);
    }, []);

    return (
        <div style={{ background: 'white' }} onContextMenu={(e) => e.preventDefault()}> {/* Cambia lo sfondo in bianco */}
            <Navbarngl />
            <UserAccessData />
            <h1 style={{ textAlign: 'center', fontFamily: 'Inter, sans-serif', color: 'black' }}>L'archivio fotografico di NGL cila</h1> {/* Cambia il colore del testo in nero */}
            <p style={{ textAlign: 'center', fontFamily: 'Inter, sans-serif', color: 'black' }}>Scopri l'archivio fotografico di NGL, le leggendarie foto di Cilavegna e Vigevano fatte dal nostro team. Le foto sono soggette a copyright. </p> {/* Cambia il colore del testo in nero */}
            <div className="container">
                <div className="row">
                    {photos.map((photo) => (
                        <div key={photo.id} className="col-12 col-md-4 mb-3">
                            <img src={photo.get('imageUrl')} alt="2px" className="img-fluid rounded shadow-lg" style={{ width: '100%', height: 'auto', pointerEvents: 'none', borderRadius: '15px' }} /> {/* Aggiunge bordi arrotondati e ombre alle immagini */}
                            <p style={{ color: 'black' }}>{photo.get('description')}</p> {/* Cambia il colore del testo in nero */}
                        </div>
                    ))}
                </div>
            </div>
            <Tops />
        </div>
    );
}

export default Archiviofoto;





