import React, { useState, useEffect, useCallback } from 'react';
import { httpsCallable } from "firebase/functions";
import { db, functions } from "./js/firebase";
import { doc, getDoc, setDoc, collection, query, where, getDocs } from "firebase/firestore";
import { Modal } from 'react-bootstrap';
import './contents/banbackground.css'; // Assicurati di creare questo file CSS

const UserAccessData = ({ onAccessDataSaved }) => {
  const [ipAddress, setIpAddress] = useState('');
  const [browserInfo] = useState({
    browserName: navigator.appName,
    browserVersion: navigator.appVersion,
    os: navigator.platform,
    language: navigator.language
  });
  const [additionalData] = useState({
    screenWidth: window.screen.width,
    screenHeight: window.screen.height,
    userAgent: navigator.userAgent
  });
  const [showBanModal, setShowBanModal] = useState(false);
  const [banMessage, setBanMessage] = useState('');
  const [bannedIPs, setBannedIPs] = useState([]);
  const [isBanned, setIsBanned] = useState(false); // Nuovo stato per gestire il blocco della pagina

  const fetchIpAddress = useCallback(async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      setIpAddress(data.ip);
    } catch (error) {
      console.error('Errore durante il recupero dell\'indirizzo IP:', error);
    }
  }, []);

  const saveAccessData = useCallback(async () => {
    const saveAccessDataFunction = httpsCallable(functions, 'saveAccessData');
    try {
      await saveAccessDataFunction({
        ipAddress,
        browserInfo,
        additionalData
      });
      console.log('Dati di accesso salvati con successo');
      if (onAccessDataSaved) {
        onAccessDataSaved();
      }
    } catch (error) {
      console.error('Errore durante il salvataggio dei dati:', error);
    }
  }, [ipAddress, browserInfo, additionalData, onAccessDataSaved]);

  const checkBannedIP = useCallback(async (ip) => {
    const bannedIPsCollection = collection(db, "BannedIPs");
    const q = query(bannedIPsCollection, where("ipAddress", "==", ip));
    try {
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const docSnap = querySnapshot.docs[0];
        const data = docSnap.data();
        const reason = data.reason;
        const expiryDate = data.expiryDate ? data.expiryDate.toDate() : null;
        const now = new Date();
        if (expiryDate && expiryDate < now) {
          await setDoc(docSnap.ref, { ...data, expired: true });
          saveAccessData();
        } else {
          const expiryMessage = expiryDate ? `Il ban scade il: ${expiryDate.toLocaleDateString()}` : 'Ban permanente';
          setBanMessage(`
            <h1 style="color: black; font-weight: bold;">Sei stato bannato</h1>
<p style="color: black">Abbiamo sospeso il tuo account per violazione delle linee guida della community.</p>
<p style="color: black">Motivazione: ${reason}</p>
<p style="color: black">${expiryMessage}</p>
          `);
          setShowBanModal(true);
          setIsBanned(true); // Imposta lo stato per bloccare la pagina
          setBannedIPs((prev) => [...prev, ip]);
        }
      } else {
        saveAccessData();
      }
    } catch (error) {
      console.error('Errore durante la verifica dell\'IP bannato:', error);
    }
  }, [saveAccessData]);

  useEffect(() => {
    fetchIpAddress();
  }, [fetchIpAddress]);

  useEffect(() => {
    if (ipAddress) {
      checkBannedIP(ipAddress);
    }
  }, [ipAddress, checkBannedIP]);

  useEffect(() => {
    if (isBanned) {
      document.body.style.overflow = 'hidden'; // Disabilita lo scrolling
    } else {
      document.body.style.overflow = 'auto'; // Abilita lo scrolling
    }
  }, [isBanned]);

  return (
    <div>
      <Modal show={showBanModal} centered>
        <Modal.Header>
          <Modal.Title style={{color: 'black'}}>
            ⚠️Accesso Negato⚠️
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: banMessage }} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserAccessData;























