import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import { fetchAccessData, fetchBannedIPs, handleConfirmBan, handleUnban } from './js/accessdatautils';

function AccessDataTable() {
  const [accessData, setAccessData] = useState([]);
  const [bannedIPs, setBannedIPs] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentIP, setCurrentIP] = useState('');
  const [banReason, setBanReason] = useState('');
  const [banDuration, setBanDuration] = useState('permanent');
  const [banExpiryDate, setBanExpiryDate] = useState(null);
  const [manualIP, setManualIP] = useState('');

  useEffect(() => {
    fetchAccessData(setAccessData);
    fetchBannedIPs(setBannedIPs);
  }, []);

  const handleBan = (ip) => {
    setCurrentIP(ip);
    setShowModal(true);
  };

  const handleManualBan = () => {
    handleBan(manualIP);
    setManualIP('');
  };

  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Orario di Accesso</th>
            <th>Indirizzo IP</th>
            <th>Coordinate</th>
            <th>Azione</th>
          </tr>
        </thead>
        <tbody>
          {accessData
            .filter((data) => !bannedIPs.includes(data.ipAddress))
            .map((data) => (
              <tr key={data.id}>
                <td>{new Date(data.dateTime.seconds * 1000).toLocaleString()}</td>
                <td>{data.ipAddress}</td>
                <td>
                  {data.location && data.location.latitude && data.location.longitude
                    ? `${data.location.latitude}, ${data.location.longitude}`
                    : 'Non concessa'}
                </td>
                <td>
                  <Button onClick={() => handleBan(data.ipAddress)}>Ban</Button>
                </td>
              </tr>
            ))}
          {bannedIPs.map((ip, index) => (
            <tr key={index}>
              <td colSpan="3">{ip}</td>
              <td>
                <Button onClick={() => handleUnban(ip, setBannedIPs, bannedIPs)}>Unban</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Form inline className="mb-3">
        <Form.Control
          type="text"
          placeholder="Inserisci IP"
          value={manualIP}
          onChange={(e) => setManualIP(e.target.value)}
          className="mr-2"
        />
        <Button onClick={handleManualBan}>Ban IP</Button>
      </Form>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Motivazione del Ban</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="banReason">
              <Form.Label>Motivazione</Form.Label>
              <Form.Control
                type="text"
                value={banReason}
                onChange={(e) => setBanReason(e.target.value)}
                placeholder="Inserisci la motivazione del ban"
              />
            </Form.Group>
            <Form.Group controlId="banDuration">
              <Form.Label>Durata del Ban</Form.Label>
              <Form.Control
                as="select"
                value={banDuration}
                onChange={(e) => setBanDuration(e.target.value)}
              >
                <option value="permanent">Permanente</option>
                <option value="temporary">Temporaneo</option>
              </Form.Control>
            </Form.Group>
            {banDuration === 'temporary' && (
              <Form.Group controlId="banExpiryDate">
                <Form.Label>Data di Scadenza</Form.Label>
                <Form.Control
                  type="date"
                  value={banExpiryDate}
                  onChange={(e) => setBanExpiryDate(e.target.value)}
                />
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Annulla
          </Button>
          <Button variant="primary" onClick={() => handleConfirmBan(currentIP, banReason, banDuration, banExpiryDate, setBannedIPs, bannedIPs, setShowModal, setBanReason, setBanDuration, setBanExpiryDate)}>
            Conferma Ban
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AccessDataTable;








