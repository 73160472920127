import React from "react";
import Navbarngl from "../navbarngl";
import Tops from "../top";


function Chisiamo () {
   return(
    <div style={{backgroundColor: 'white'}}>
       <Navbarngl />
       <h1 style={{textAlign: 'center'}}>Chi siamo</h1>
       <p>Siamo un gruppo di ragazzi della vostra età a cui piace comunicare gossip e notizie riguardo Cilavegna e dintorni. Trattiamo i temi dei fidanzamenti, delle notizie, il calcio, lo sport e notizie importanti a livello Nazionale. Ci fa felici verere che abbiamo raggiunto questo grande risultato e che sta continuando ad averli.  <br />
       Per ulteriori informazioni su noi contattae la nostra live chat integrata (non ancora presente), nei messaggi anonimi NGL, i DM di instagram e l'indirizzo e-mail info@nglcila.com</p>
       <br />
       <br />
       <br />
       <br />
       <br />
       <br />
       <br />
       <br />
       <br />
       <br />
       <br />
       <br />
       <br />
       <br />
       <br />
       <br />
       <br />
       <br />
       <br />
       <br />
       <br />
       <Tops />

    </div>
   );
}

export default Chisiamo;