import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import Parse from 'parse';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useUser } from './js/usercontext';

const AddProductButton = ({ onAddProduct }) => {
  const { user } = useUser();
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [price, setPrice] = useState('');
  const [sellerName, setSellerName] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [paymentLink, setPaymentLink] = useState('');
  const [wordCount, setWordCount] = useState(0);

  useEffect(() => {
    if (user) {
      setSellerName(user.get('username'));
    }
  }, [user]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleImageUpload = async (file) => {
    const parseFile = new Parse.File(file.name, file);
    await parseFile.save();
    return parseFile.url();
  };

  const handleTextChange = (value) => {
    setText(value);
    setWordCount(value.split(/\s+/).filter(word => word.length > 0).length);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let uploadedImageUrl = imageUrl;
    if (imageFile) {
      uploadedImageUrl = await handleImageUpload(imageFile);
    }

    const ShopCard = Parse.Object.extend('ShopCard');
    const shopCard = new ShopCard();
    shopCard.set('title', title);
    shopCard.set('text', text);
    shopCard.set('imageUrl', uploadedImageUrl);
    shopCard.set('price', price);
    shopCard.set('sellerName', sellerName);
    shopCard.set('paymentLink', paymentLink);

    try {
      await shopCard.save();
      onAddProduct({ title, text, imageUrl: uploadedImageUrl, price, sellerName, paymentLink });
      handleClose();
    } catch (error) {
      console.error('Errore nel salvataggio del prodotto:', error);
    }
  };

  return (
    <>
      <Button variant="success" onClick={handleShow}>+</Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Aggiungi Nuovo Prodotto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formTitle">
              <Form.Label>Titolo</Form.Label>
              <Form.Control type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
            </Form.Group>
            <Form.Group controlId="formText">
              <Form.Label>Descrizione</Form.Label>
              <ReactQuill value={text} onChange={handleTextChange} modules={{
                toolbar: [
                  [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                  [{size: []}],
                  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                  [{'list': 'ordered'}, {'list': 'bullet'}, 
                   {'indent': '-1'}, {'indent': '+1'}],
                  ['link', 'image'],
                  ['clean']                                         
                ]
              }} />
              <div>Parole: {wordCount}/10000</div>
            </Form.Group>
            <Form.Group controlId="formImageUrl">
              <Form.Label>URL Immagine</Form.Label>
              <Form.Control type="text" value={imageUrl} onChange={(e) => setImageUrl(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="formImageFile">
              <Form.Label>Carica Immagine</Form.Label>
              <Form.Control type="file" onChange={(e) => setImageFile(e.target.files[0])} />
            </Form.Group>
            <Form.Group controlId="formPrice">
              <Form.Label>Prezzo</Form.Label>
              <Form.Control type="text" value={price} onChange={(e) => setPrice(e.target.value)} required />
            </Form.Group>
            <Form.Group controlId="formSellerName">
              <Form.Label>Nome del Venditore</Form.Label>
              <Form.Control type="text" value={sellerName} readOnly />
            </Form.Group>
            <Form.Group controlId="formPaymentLink">
              <Form.Label>Link di Pagamento</Form.Label>
              <Form.Control type="text" value={paymentLink} onChange={(e) => setPaymentLink(e.target.value)} />
            </Form.Group>
            <Button variant="primary" type="submit">
              Salva
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddProductButton;









