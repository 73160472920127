import React from "react";

import sfondo from '../contents//sfondo.png';
import { Container, Row, Col, Image } from "react-bootstrap";
import Tops from "../top";
import vulcano from '../contents/vulcano.avif'
import Navbarngl from "../navbarngl";
import Casabianca from '../contents/wh.jpg'
import oro from '../contents/erebus-oro.avif';
import render from '../contents/0380.avif'
import interni from '../contents/download.jpg'
function CasaBianca() {
    return(
        <div style={{backgroundImage: `url(${sfondo})` }}>
    <Navbarngl />
 <h1 style={{fontstyle: 'bold', fontFamily: 'Impact', fontSize: '60px'}}>NGLPop</h1>
 <div  className='div-centrale' style={{backgroundColor: 'white'}}>
        <p style={{marginLeft: '8px', fontSize: '12px'}}>17 Luglio 2024 * 12:10 Curiosità e Cultura</p>
<Container>
<h1 style={{ fontFamily: 'Arial Black', marginLeft: '8px', fontsize: '16px'}}> LA CASA BIANCA: UN’ICONA DI ELEGANZA E POTERE</h1>
<p style={{marginLeft: '10px'}}>
La Casa Bianca è famosa in tutto il mondo non solo per la sua <strong>grandezza</strong>, ma anche perché è la <strong>residenza ufficiale del Presidente degli Stati Uniti</strong>. Situata al <strong>1600 di Pennsylvania Avenue a Washington D.C.</strong>, è stata la casa di ogni presidente americano a partire da <strong>John Adams nel 1800</strong>.
<Row>
  <Col xs={12} sm={6} md={4} lg={3}>
    <Image src={Casabianca} rounded fluid />
    <p>Foto della Casa Bianca. Credits : Niiprogetti.it</p>
  </Col>
</Row>
<h1 style={{ fontFamily: 'Arial Black', marginLeft: '8px', fontsize: '16px'}}> DA QUANTE STANZE E' COMPOSTA, E QUALI SONO I LORO RUOLI?</h1>
<p>La Casa Bianca, con i suoi 6 piani e una superficie di circa 5000 m², è un simbolo di potere e storia. Questo edificio maestoso ospita 132 stanze, 147 finestre, 35 bagni, 8 scale e 3 ascensori, e si erge per un'altezza di 21,33 metri.</p>
    
    <h2>Il Corpo Centrale</h2>
    <p><strong>Secondo Piano</strong>: Qui si trovano le stanze private del presidente e della sua famiglia, offrendo un rifugio tranquillo e confortevole lontano dagli impegni ufficiali.</p>
    <p><strong>Primo Piano</strong>: Questo piano è ricco di stanze storiche e significative:</p>
    <ul>
        <li><strong>Stanza Verde</strong>: Originariamente utilizzata come sala da tè, questa stanza è decorata con un elegante tema verde.</li>
        <li><strong>Stanza Rossa</strong>: Utilizzata per ricevimenti ufficiali, è famosa per il suo arredamento rosso intenso.</li>
        <li><strong>Stanza Blu</strong>: Conosciuta per ospitare l'albero di Natale ogni anno, questa stanza è un punto focale delle festività.</li>
    </ul>
    
    <h2>Le Ali Laterali</h2>
    <p><strong>Ala Est</strong>: Questa sezione della Casa Bianca è il punto di partenza per le visite guidate e ospita vari eventi pubblici.</p>
    <p><strong>Ala Ovest</strong>: Qui si trova l'ufficio del presidente, il cuore delle operazioni esecutive.</p>
    
    <h2>Caratteristiche Speciali</h2>
    <p><strong>Vetri Antiproiettile</strong>: La sicurezza è una priorità, con vetri progettati per resistere agli attacchi. Ogni presidente ha la libertà di decorare la Casa Bianca secondo il proprio gusto personale.</p>
    <p><strong>Situation Room</strong>: Questa stanza è cruciale per la sicurezza nazionale, dove il presidente e i suoi ministri prendono decisioni vitali.</p>
    
    <h2>Aree di Svago</h2>
    <p><strong>Cinema</strong>: Un luogo per rilassarsi e godersi film privati.</p>
    <p><strong>Piscina</strong>: Perfetta per l'esercizio e il relax.</p>
    <p><strong>Biblioteca</strong>: Un rifugio tranquillo per la lettura e lo studio.</p>
    <Row>
  <Col xs={12} sm={6} md={4} lg={3}>
    <Image src={render} rounded fluid />
    <p>render dell'interno della casa bianca. Fonti: Geopop</p>
  </Col>
</Row>
    <h1>Sicurezza e Accesso alla Casa Bianca</h1>
    <p>La Casa Bianca si trova in una zona di volo altamente controllata, con lanciatori di missili terra-aria pronti a intervenire in caso di minacce. Il perimetro è sorvegliato da telecamere a infrarossi e circondato da una recinzione alta 3,3 metri, dotata di sensori di pressione che avvertono la sicurezza se qualcuno tenta di scavalcare. Le finestre sono antiproiettile e la Situation Room è progettata per resistere anche a un attacco nucleare.</p>
    <p>Nonostante le misure di sicurezza, la Casa Bianca è anche un museo visitato ogni giorno da migliaia di persone. Per garantire la sicurezza di tutti, è necessario prenotare la visita con almeno 21 giorni di anticipo, permettendo alle autorità di verificare i documenti di identità e i passaporti di ogni visitatore.</p>
</p>
<Row>
  <Col xs={12} sm={6} md={4} lg={3}>
    <Image src={interni} rounded fluid />
    <p></p>
  </Col>
</Row>
</Container>
</div>
<h3 style={{borderLeft: '20px'}}>Fonti:
    Geopop, Wikipedia e Niiprogetti
</h3>
<Tops />
</div>
    )
}


export default CasaBianca;
