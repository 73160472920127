import React from "react";
import Navbarngl from "../navbarngl";
import Tops from "../top";
import j62024 from '../contents/j624062024.png';
import j72024 from '../contents/j62024.jpg';
import j82024 from '../contents/j82024.png';
import { Col, Card, Row, Container } from "react-bootstrap";
import sfondo from '../contents/sfondo.png';

function Football() {
    return (
        <div style={{ background: 'linear-gradient(to bottom, #00008B, #87CEEB)', fontFamily: 'Inter, sans-serif', paddingBottom: '20px' }}>
            <Navbarngl />
            <div style={{ marginBottom: '20px' }}>
                <h1 style={{ color: 'white', textAlign: 'center' }}>Notizie sul Calcio (Olimpic 95) e Campionati nazionali e europei</h1>
            </div>
            <Container>
                
            </Container>
            <Tops />
        </div>
    );
}

export default Football;

