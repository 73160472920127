import React from "react";
import Navbarngl from "../navbarngl";
import Tops from "../top";
import sfondo from '../contents/sfondo.png'
import  {Container, Row, Col, Image} from 'react-bootstrap'
import autostima from '../contents/autostima.jpg'
import autostima2 from '../contents/autostima2.jpg'
import autostima3 from '../contents/autostima3.png'

function Autostima () 
{
    return(
        <div style={{backgroundImage: `url(${sfondo})` }}>
            <Navbarngl />
            <h1 style={{fontstyle: 'bold', fontFamily: 'Impact', fontSize: '60px'}}>NGLPop</h1>
            <div  className='div-centrale' style={{backgroundColor: 'white'}}>
        <p style={{marginLeft: '8px', fontSize: '12px'}}>9 Luglio 2024 * 22:00 Psicologia </p>
        <Container>
       <h1 style={{ fontFamily: 'Arial Black', marginLeft: '8px', fontsize: '16px'}}> L'AUTOSTIMA</h1>
       <h2 style={{  marginLeft: '15px'}}> Che cos’è l’autostima? </h2>
       <p><strong>Avere alta autostima significa:</strong></p>
<ul>
  <li>Riuscire ad ammettere quali sono i nostri <strong>difetti</strong> senza però oscurare i <strong>pregi</strong></li>
  <li>Essere in grado di <strong>apprezzarsi</strong>, paragonando i nostri risultati al posto di paragonarli a quelli degli altri</li>
  <li>Accettare il nostro <strong>fallimento</strong> nel raggiungere un obbiettivo piuttosto che un traguardo, senza mai pensare di essere un “<strong>fallito</strong>”.</li>
</ul>
<p>L'<strong>autostima</strong> è come se fosse una valutazione finale di ciò che siamo noi.</p>
<Row>
        <Col xs={6} md={4}>
          <Image src={autostima} rounded />
          <p>Foto sull'autostima</p>
          
        </Col>
</Row>
<h2 style={{  marginLeft: '15px'}}> Che cos’è la mancanza di autostima? </h2>
<p>La <strong>mancanza di autostima</strong> è un fenomeno che nasce da una complessità di fattori collegati. Durante un determinato periodo della nostra vita, definito da tanti cambiamenti fisici, emotivi e sociali, può mettere in difficoltà l’impressione che i giovani hanno di loro stessi.</p>
<p>Le trasformazioni corporee sono i fattori principali che causano una caduta di autostima, dovuto al fatto che i giovani hanno diverse aspettative personali e quindi non vedendosi come loro vorrebbero li porta ad avere <strong>bassa autostima</strong> nei loro confronti. Anche l’appartenenza a un gruppo di persone pari a loro stessi è molto importante nella fase dell’adolescenza perché essere accettati e sentirsi parte di una comunità, influisce significativamente sull’autostima. L’esclusione sociale, il bullismo o la percezione di non essere all’altezza delle proprie aspettative possono consumare la fiducia in sé stessi.</p>
<p>La famiglia è molto fondamentale. Il sostegno affettivo, il riconoscimento delle competenze e l’incoraggiamento alla sperimentazione sono elementi che aiutano ad avere una <strong>solida autostima</strong>. Al contrario, dinamiche familiari conflittuali o troppo critiche possono compromettere il senso di valore personale dell’adolescente.</p>
<Row>
        <Col xs={6} md={4}>
          <Image src={autostima2} rounded />
         
          
        </Col>
</Row>
<h2 style={{  marginLeft: '15px'}}> L'impatto dell'era digitale sull'autostima </h2>

<p>L’impatto dell’<strong>ambiente digitale</strong> non è da trascurare. I social media aumentano le opportunità di confronto con gli altri, esponendo i giovani a standard irrealistici di bellezza, successo e popolarità e questi causano tante frustrazioni ai giovani.</p>
<p>La <strong>bassa autostima</strong>, con l’incremento dei fenomeni di ansia e depressione possono causare suicidi tra gli studenti universitari. Gli adolescenti si trovano spesso in processi di auto-valutazione, spesso falsificate da numerose pressioni sociali. È scientificamente studiato che avere un’<strong>autostima solida</strong> durante l’adolescenza, permette agli adolescenti di affrontare gli ostacoli della loro crescita sapendo prendere delle decisioni e riuscendo a relazionarsi senza timore con i coetanei.</p>
<p>Tutte le forme di <strong>bullismo</strong>, possono causare danni permanenti sulle persone. Non tutti gli adolescenti sono uguali, tanto che le critiche, le prese in giro e i commenti negativi scritti sotto a un post appena pubblicato su Instagram, ad alcuni adolescenti non fa nulla perché sono in grado di non dare peso alle parole mentre altre persone non riescono a far finta di nulla e tendono a suicidarsi, anche solo per un commento poco piacevole sotto una foto. Ogni anno sono circa <strong>2.000 gli adolescenti</strong> che si tolgono la vita e che per ogni tentativo riuscito ce ne sono circa <strong>350 falliti</strong>.</p>
<Row>
        <Col xs={6} md={4}>
          <Image src={autostima3} rounded />
         
          
        </Col>
</Row>
       </Container>

        </div>
        <h3 style={{borderLeft: '20px'}}>Fonti:
    Nglcila.com </h3>
        <Tops />
        </div>
    );
}

export default Autostima;