import React, { useEffect, useState } from 'react';
import Parse from 'parse';
import { Carousel, Image, Container } from 'react-bootstrap';
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';

const StyledContainer = styled(Container)`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  border-radius: 16px; /* Arrotonda i bordi */
  border: 2px solid white;
  padding: 20px;
  background-color: black; /* Cambia lo sfondo in nero */
  color: white;

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

const StyledText = styled.div`
  flex: 1;
  text-align: center;
  padding: 20px;
  color: white;

  h2, h3, p {
    animation: fadeIn 1s;
    font-family: 'Inter, sans-serif';
  }

  h2 {
    color: white;
    font-weight: bold;
  }

  h3 {
    color: white;
    font-weight: bold;
  }

  ul {
    list-style-type: disc;
    padding-left: 1rem;
    font-size: 1.25rem;

    li {
      color: white;
      font-weight: bold;

      span {
        color: white;
      }
    }
  }
`;

const StyledCarousel = styled(Carousel)`
  flex: 2;
  width: 100%;
  padding: 20px;

  .carousel-image {
    border-radius: 16px;
  }
`;

const StyledImage = styled(Image)`
  border-radius: 16px;
  width: 100%;
  height: auto;
`;

const ReactCarousel = () => {
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    const fetchPhotos = async () => {
      const PhotoData = Parse.Object.extend('PhotoData');
      const query = new Parse.Query(PhotoData);
      try {
        const results = await query.find();
        setPhotos(results.map(photo => photo.get('imageUrl')));
      } catch (error) {
        console.error('Error while fetching photos', error);
      }
    };

    fetchPhotos();
  }, []);

  return (
    <StyledContainer>
      <StyledContent>
        <StyledText>
          <h2 style={{fontFamily: 'Inter, sans-serif'}}>Le foto dell'Archivio fotografico</h2>
          <h3 style={{fontFamily: 'Inter, sans-serif'}}>Esplora l'archivio</h3>
          <p style={{fontFamily: 'Inter, sans-serif'}}>Scopri l'archivio delle foto di proprietà di NGL, consultando la sezione galleria nella nostra navbar!</p>
          <ul style={{fontFamily: 'Inter, sans-serif'}}>
            <li><span>Archivio Storico</span></li>
            <li><span>Materiale Fotografico Unico</span></li>
            <li><span>Cilavegna e comuni intorno</span></li>
            <li><span>Collezione in Continua Espansione</span></li>
          </ul>
          <div className="mt-6">
            <a href="/archiviofoto" className="text-indigo-600 hover:text-indigo-900 font-semibold text-lg">Scopri di più &rarr;</a>
          </div>
        </StyledText>
        <StyledCarousel indicators={true} interval={5000}>
          {photos.map((url, index) => (
            <Carousel.Item key={index}>
              <StyledImage
                src={url}
                alt={`Slide ${index}`}
                fluid
                className="carousel-image"
              />
            </Carousel.Item>
          ))}
        </StyledCarousel>
      </StyledContent>
    </StyledContainer>
  );
};

export default ReactCarousel;













