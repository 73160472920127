import React from "react";
import { Navbar, Container, Nav, NavDropdown, Button } from 'react-bootstrap';
import { FaInstagram, FaGamepad, FaWhatsapp, FaTiktok } from 'react-icons/fa';
import { ReactComponent as Ngllogo } from './contents/nglicon.svg';
import ngllogo from './contents/ngllogo.svg';
import { ReactSVG } from 'react-svg';
import { ReactComponent as Anologo }  from './contents/anonymouss.svg';
import styled, { keyframes } from 'styled-components';
import { useUser } from './js/usercontext';

const slideDown = keyframes`
  0% { transform: translateY(-100%); }
  100% { transform: translateY(0); }
`;

const scaleUp = keyframes`
  0% { transform: scale(1) rotate(0deg); }
  100% { transform: scale(1.2) rotate(360deg); }
`;

const AnimatedNavbar = styled(Navbar)`
  animation: ${slideDown} 1s ease-in-out;
  background-color: #000; /* Nero */
`;

const SocialIcon = styled(Nav.Link)`
  transition: transform 0.3s ease-in-out;
  &:hover {
    animation: ${scaleUp} 0.5s ease-in-out forwards;
  }

  /* Disabilita l'animazione su dispositivi mobili */
  @media (max-width: 768px) {
    &:hover {
      animation: none;
    }
  }
`;

const LoginButton = styled(Button).attrs({
  className: 'login-button'
})`
  background: none;
  border: 2px solid white;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.375rem 0.75rem;
  margin-left: 1rem;
  border-radius: 10px; /* Arrotonda il pulsante */
  
  &:hover {
    background: white;
    color: #000;
  }
`;

const StyledNgllogo = styled(Ngllogo)`
  width: 30px;
  height: 30px;
  fill: white;
`;

const StyledAnologo = styled(Anologo)`
  width: 30px;
  height: 30px;
  fill: white;
`;

function Navbarngl() {
    const { user } = useUser();

    return (
        <AnimatedNavbar collapseOnSelect expand="lg" style={{ fontFamily: 'Inter, sans-serif' }} variant="dark">
            <Container>
                <Navbar.Brand href="#home" aria-label="Home">
                    <img
                        alt="NGL Cila Logo"
                        src={ngllogo}
                        width="60"
                        height="60"
                        className="d-inline-block align-top"
                        onContextMenu={(e) => e.preventDefault()}
                        onDragStart={(e) => e.preventDefault()}
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Navbar.Text className="text-white fs-1 fw-bold d-none d-lg-block">
                            Ngl Cila
                        </Navbar.Text>
                    </Nav>
                    <Nav className="ms-auto">
                        <Nav.Link href="/home" style={{ color: 'white' }} aria-label="Home section">Home</Nav.Link>
                        <Nav.Link href="/news" style={{ color: 'white' }} aria-label="News section">News</Nav.Link>
                        <Nav.Link href="/archiviofoto" style={{ color: 'white' }} aria-label="Galleria section">Galleria</Nav.Link>
                        <Nav.Link href="/shop" style={{ color: 'white' }} aria-label="Eventi section">Shop</Nav.Link>
                        <NavDropdown title="Altro" id="navbarScrollingDropdown" aria-label="Altro section">
                            <NavDropdown.Item href="/download" aria-label="Download app">Scarica l'app di NGL Cila</NavDropdown.Item>
                            <NavDropdown.Item href="/orarioautoguidovie" aria-label="orario Linea 106">Orario Linea 106</NavDropdown.Item>
                            <NavDropdown.Item href="/cilastudios" aria-label="Cila Studios">Cila Studios</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    <Nav className="ms-3">
                        <SocialIcon href="https://www.instagram.com/ngl_cila" aria-label="Instagram">
                            <FaInstagram size={30} color="white" />
                        </SocialIcon>
                        <SocialIcon href="https://whatsapp.com/channel/0029VajF80s6BIEcButOf50E" aria-label="WhatsApp">
                            <FaWhatsapp size={30} color="white" />
                        </SocialIcon>
                        <SocialIcon href="https://www.tiktok.com/@ngl_cila?_t=8pip3VNfjG4&_r=1" aria-label="Tik Tok">
                            <FaTiktok size={30} color="white" />
                        </SocialIcon>
                        <SocialIcon href="/minigioco" aria-label="Minigioco">
                            <FaGamepad size={30} color="white" />
                        </SocialIcon>
                        <SocialIcon href="https://www.ngl.link/ngl_cila2" aria-label="NGL">
                            <StyledNgllogo />
                        </SocialIcon>
                        <SocialIcon href="https://anonymouss.me/ngl_cila" aria-label="Anonymouss">
                            <StyledAnologo />
                        </SocialIcon>
                        {user ? (
                            <LoginButton>
                                {user.displayName || user.email}
                            </LoginButton>
                        ) : (
                            <LoginButton href="/login-auth">
                                Accedi
                            </LoginButton>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </AnimatedNavbar>
    );
}

export default Navbarngl;







































