import React from "react";
import image from './contents/Anonimuss.png'

function Anonymus () {
    return(
    <div>
        <img src={image}></img>
    </div>
    );
}


export default Anonymus;