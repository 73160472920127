import React from "react";
import { Navbar, Container, Nav, NavDropdown, Button } from 'react-bootstrap';
import { FaInstagram, FaGamepad, FaWhatsapp, FaTiktok } from 'react-icons/fa';
import { ReactComponent as Ngllogo } from './contents/nglicon.svg';
import ngllogo from './contents/nglvige.png';
import { ReactComponent as Anologo }  from './contents/anonymouss.svg';
import styled, { keyframes } from 'styled-components';
import { useUser } from './js/usercontext';

const slideDown = keyframes`
  0% { transform: translateY(-100%); }
  100% { transform: translateY(0); }
`;

const scaleUp = keyframes`
  0% { transform: scale(1) rotate(0deg); }
  100% { transform: scale(1.2) rotate(360deg); }
`;

const AnimatedNavbar = styled(Navbar)`
  animation: ${slideDown} 1s ease-in-out;
  background-color: #007bff; /* Azzurro */
`;

const SocialIcon = styled(Nav.Link)`
  transition: transform 0.3s ease-in-out;
  &:hover {
    animation: ${scaleUp} 0.5s ease-in-out forwards;
  }

  /* Disabilita l'animazione su dispositivi mobili */
  @media (max-width: 768px) {
    &:hover {
      animation: none;
    }
  }
`;

const LoginButton = styled(Button).attrs({
  className: 'login-button'
})``;

function Navbarngl() {
    const { user } = useUser();

    return (
        <AnimatedNavbar collapseOnSelect expand="lg" style={{ fontFamily: 'Inter, sans-serif' }} variant="dark">
            <Container>
                <Navbar.Brand href="#home" aria-label="Home">
                    <img
                        alt="NGL Vige Logo"
                        src={ngllogo}
                        width="60"
                        height="60"
                        className="d-inline-block align-top"
                        onContextMenu={(e) => e.preventDefault()}
                        onDragStart={(e) => e.preventDefault()}
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Navbar.Text className="text-white fs-1 fw-bold d-none d-lg-block">
                            Ngl Vige
                        </Navbar.Text>
                    </Nav>
                    <Nav className="ms-auto">
                        <Nav.Link href="/nglvige" style={{ color: 'white' }} aria-label="Home section">Home</Nav.Link>
                        <Nav.Link href="/news" style={{ color: 'white' }} aria-label="News section">News</Nav.Link>
                        <Nav.Link href="/archiviofoto" style={{ color: 'white' }} aria-label="Galleria section">Galleria</Nav.Link>
                        <Nav.Link href="/eventi" style={{ color: 'white' }} aria-label="Eventi section">Eventi</Nav.Link>
                        <NavDropdown title="Altri nostri progetti" id="navbarScrollingDropdown" aria-label="Altri nostri progetti section" style={{ color: 'white' }}>
                            <NavDropdown.Item href="https://roggiafanpage.nglcila.com" aria-label="Roggia Fanpage">Roggia Fanpage</NavDropdown.Item>
                            <NavDropdown.Item href="/cilastudios" aria-label="Visualizzatore modelli minecraft">Cila Studios</NavDropdown.Item>
                            <NavDropdown.Item href="/ciclovie" aria-label="Ciclovie di NGL Cila">Ciclovie di NGL Cila</NavDropdown.Item>
                            <NavDropdown.Item href="/home" aria-label="Cila">NGL Cila</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    <Nav className="ms-3">
                        <SocialIcon href="https://www.instagram.com/ngl_vigee" aria-label="Instagram">
                            <FaInstagram size={30} color="white" />
                        </SocialIcon>
                        
                        <SocialIcon href="https://anonymouss.me/ngl_vigee" aria-label="Anonymouss">
                            <Anologo width="30" height="30" color="white" />
                        </SocialIcon>
                        
                    </Nav>
                </Navbar.Collapse>
            </Container>
            <style jsx>
                {`
             /* Definisci lo stile del pulsante di login */
.login-button {
  background: none;
  border: 2px solid white;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.375rem 0.75rem;
  margin-left: 1rem;
  border-radius: 10px; /* Arrotonda il pulsante */
}

.login-button:hover {
  background: white;
  color: #007bff;
}

                `}
            </style>
        </AnimatedNavbar>
    );
}

export default Navbarngl;