// Dashboard.js
import React, { useState, useEffect } from "react";
import Navbarngl from "../navbarngl";
import Tops from "../top";
import ReviewCard from "../reviewcard";
import CardForm from "../CardForm";
import AccessDataTable from "../Tabella";
import PhotoForm from "../Photoform";
import StatisticsPanel from "../statisticspanel";

import { Modal, Button, Dropdown, Container, Row, Col } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import AddNewsForm from "../addnewsform";
import { useNavigate } from 'react-router-dom';
import OrdiniEffettuati from "../ordinieffettuati";
import { fetchStatisticsData, checkCurrentUser } from '../js/dashboardutils';
import NewsForm from "../newsform";

function Dashboard() {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [statisticsData, setStatisticsData] = useState([]);

    useEffect(() => {
        checkCurrentUser(navigate);
    }, [navigate]);

    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false);
        setSelectedOption('');
    };

    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };

    const renderForm = () => {
        if (selectedOption === 'NGLPop') {
            return <CardForm />;
        } else if (selectedOption === 'Archivio Fotografico') {
            return <PhotoForm onPhotoAdded={() => {}} />;
        } else if (selectedOption === 'News di Cila') {
            return <AddNewsForm />;
        } else if (selectedOption === 'News prima pagina') {
            return <NewsForm />;
        }
        return null;
    };

    return (
        <div style={{ backgroundColor: 'white' }}>
            <Navbarngl />
            <Container>
                <Row className="my-4">
                    <Col>
                        <h1>Dashboard Amministrazione sito web</h1>
                    </Col>
                    <Col className="text-right">
                        <Button
                            variant="primary"
                            onClick={handleShow}
                            style={{
                                borderRadius: '50%',
                                width: '50px',
                                height: '50px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <FaPlus />
                        </Button>
                    </Col>
                </Row>
                <StatisticsPanel data={statisticsData} />

               

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Cosa vuoi creare?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Dropdown.Item onClick={() => handleOptionChange('NGLPop')}>NGLPop</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleOptionChange('Archivio Fotografico')}>Archivio Fotografico</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleOptionChange('News di Cila')}>News di Cila</Dropdown.Item>
                        
                        {renderForm()}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Chiudi
                        </Button>
                    </Modal.Footer>
                </Modal>

                <h1>Recensioni sito web</h1>
                <ReviewCard />
                <OrdiniEffettuati />
                <h1>Gestione accessi sito web</h1>
                <AccessDataTable />

                <Tops />
            </Container>
        </div>
    );
}

export default Dashboard;















