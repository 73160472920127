import React, { useEffect, useState } from 'react';
import { Navbar, Container } from 'react-bootstrap';
import { FaCalendarAlt, FaClock, FaMapMarkerAlt } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled, { keyframes } from 'styled-components';

const Navorario = () => {
  const [currentTime, setCurrentTime] = useState('');
  const [location, setLocation] = useState('');

  const AnimatedNavbars = styled(Navbar)`
  
  background-color: #000; /* Nero */
`;


  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
      let timeString = now.toLocaleDateString('it-IT', options);
      timeString = timeString.charAt(0).toUpperCase() + timeString.slice(1);
      const timeParts = timeString.split(' alle ');
      const datePart = timeParts[0];
      const timePart = timeParts[1];
      setCurrentTime(
        <>
          <span className="Navorario"><FaCalendarAlt /> {datePart}</span> <span className="Navorario"><FaClock /> {timePart}</span>
        </>
      );
    };

    const fetchLocation = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        setLocation(`${data.city}, ${data.region}, ${data.country}`);
      } catch (error) {
        console.error('Error fetching location:', error);
      }
    };

    updateTime();
    fetchLocation();
    const intervalId = setInterval(updateTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <AnimatedNavbars>
    
      <Container className="d-flex justify-content-between">
        <div>
          {currentTime}
        </div>
        <div className="Navorario"><FaMapMarkerAlt /> {location}</div>
      </Container>
      <style jsx>
        {`
          .Navorario {
            display: flex;
            align-items: center;
          }

          .Navorario svg {
            margin-right: 5px;
          }

          .navbar-dark .navbar-brand, .navbar-dark .navbar-nav .nav-link {
            color: #fff;
          }
        `}
      </style>
    
    </AnimatedNavbars>
  );
};

export default Navorario;






