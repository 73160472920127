import React from "react";
import Tops from "./top";
import Navbarngl from "./Navbarnglvige";
import Buttonesvige from "./buttonesvige";
import Navorario from "./navorario";
import ReactCarousel from "./CarousePhoto";
import Ultimipost from "./ultimipost";
import Cardse from "./cardse";


function HomeNglVige () {

    return (
        <div style={{ background: 'linear-gradient(to bottom, #8c53ff, #5de0e6)' }}>
            <Navbarngl />
            <Navorario />
            <Buttonesvige />
            <div style={{ color: 'white' }}>
           <Cardse  />
            <ReactCarousel />
            </div>
      
            <Tops />
        </div>
    );

}

export default HomeNglVige;