import React, { useEffect, useState } from 'react';
import { Card, Button, Container, Row, Col, Form } from 'react-bootstrap';
import Parse from 'parse';

function Popcard() {
  const [cards, setCards] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    // Recuperare i dati delle card dal database
    const fetchCardData = async () => {
      const CardData = Parse.Object.extend('CardData');
      const query = new Parse.Query(CardData);

      try {
        const results = await query.find();
        setCards(results);
      } catch (error) {
        console.error('Error while fetching card data: ', error);
      }
    };

    fetchCardData();
  }, []);

  const filteredCards = cards.filter(card =>
    card.get('title').toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div style={{ padding: '20px 0' }}>
      <Container>
        <Form.Group controlId="search" style={{ marginBottom: '20px' }}>
          <Form.Control
            type="text"
            placeholder="Cerca un NGLPop!"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ fontFamily: 'Inter, sans-serif', borderRadius: '15px', border: '2px solid black' }} 
          />
        </Form.Group>
        <Row className="g-4"> {/* g-4 aumenta lo spazio tra le colonne */}
          {filteredCards.map((card) => (
            <Col md={6} lg={4} className="d-flex align-items-stretch" key={card.id}>
              <Card style={{ width: '100%', height: 'auto', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', backgroundColor: 'black', color: 'white', borderRadius: '15px' }}> {/* Cambia le card a sfondo scuro, testo bianco e bordi arrotondati */}
                <Card.Img variant="top" src={card.get('imageUrl')} style={{ borderTopLeftRadius: '15px', borderTopRightRadius: '15px' }} /> {/* Arrotonda le immagini */}
                <Card.Body>
                  <Card.Title style={{ fontFamily: 'Inter, sans-serif', fontWeight: 'bold' }}>{card.get('title')}</Card.Title>
                  <Card.Text style={{ fontFamily: 'Inter, sans-serif' }}>{card.get('text')}</Card.Text>
                  <Button variant="primary" as="a" href={card.get('buttonLink')} style={{ fontFamily: 'Inter, sans-serif', borderRadius: '50px' }}>Leggi l'articolo</Button> {/* Arrotonda il pulsante */}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default Popcard;






