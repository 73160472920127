import React, { useState } from 'react';
import { Form, Button, Modal, Container, Image } from 'react-bootstrap';
import { GoogleAuthProvider, signInWithPopup, createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../js/firebase';
import Navbarngl from '../navbarngl';
import Tops from '../top';

const RegistrationForm = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (termsAccepted) {
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        await setDoc(doc(db, 'users', user.uid), {
          username,
          email
        });
        setModalMessage('Registrazione completata!');
        setShowModal(true);
      } catch (error) {
        setModalMessage('Errore nella registrazione: ' + error.message);
        setShowModal(true);
      }
    } else {
      alert("Devi accettare i termini e le condizioni.");
    }
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      await setDoc(doc(db, 'users', user.uid), {
        username: user.displayName,
        email: user.email
      });
      window.location.href = '/home';
    } catch (error) {
      alert('Errore nel login con Google: ' + error.message);
    }
  };

  return (
    <div style={{ backgroundColor: '#f8f9fa', fontFamily: 'Inter, sans-serif', minHeight: '100vh' }}>
      <Navbarngl />
      <Container className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
        <div style={{ width: '100%', maxWidth: '400px', padding: '2rem', backgroundColor: '#ffffff', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
          <Image src="https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg" rounded style={{ maxWidth: '150px', marginBottom: '20px' }} alt="Logo" />
          <Form onSubmit={handleSubmit} className="w-100">
            <Form.Group controlId="formUsername">
              <Form.Label>Nome Utente</Form.Label>
              <Form.Control
                type="text"
                placeholder="Inserisci il nome utente"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                aria-label="Nome Utente"
                required
                style={{ fontSize: '1.2rem', borderRadius: '10px', marginBottom: '10px' }}
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Inserisci l'email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                aria-label="Email"
                required
                style={{ fontSize: '1.2rem', borderRadius: '10px', marginBottom: '10px' }}
              />
            </Form.Group>
            <Form.Group controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Inserisci la password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                aria-label="Password"
                required
                style={{ fontSize: '1.2rem', borderRadius: '10px', marginBottom: '10px' }}
              />
            </Form.Group>
            <Form.Group controlId="formTerms">
              <Form.Check
                type="checkbox"
                label="Accetto i termini e le condizioni"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
                aria-label="Accetto i termini e le condizioni"
                required
                style={{ marginBottom: '20px' }}
              />
            </Form.Group>
            <Button variant="primary" type="submit" style={{ width: '100%', padding: '0.75rem', fontSize: '1.2rem', borderRadius: '10px', marginBottom: '10px' }}>
              Registrati
            </Button>
            <Button
              variant="light"
              onClick={handleGoogleLogin}
              style={{ width: '100%', padding: '0.75rem', fontSize: '1.2rem', borderRadius: '10px', marginBottom: '10px' }}
            >
              Accedi con Google
            </Button>
          </Form>
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Registrazione Completata</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalMessage}</Modal.Body>
            <Modal.Footer>
              <Button variant="success" onClick={() => window.location.href = '/login'}>Vai al Login</Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Container>
      <Tops />
    </div>
  );
};

export default RegistrationForm;









