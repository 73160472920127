import React, { useState, useEffect, useRef } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import { FaNewspaper } from "react-icons/fa";
import Parse from "parse";

const NewsList = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [newsItems, setNewsItems] = useState([]);
  const cardsRef = useRef([]);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (
      /android/i.test(userAgent) ||
      (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
    ) {
      setIsMobile(true);
    }

    const fetchNews = async () => {
      const News = Parse.Object.extend("News");
      const query = new Parse.Query(News);
      query.descending("createdAt");
      query.limit(3);
      try {
        const results = await query.find();
        const newsData = results.map((result) => ({
          title: result.get("title"),
          content: result.get("content"),
        }));
        setNewsItems(newsData);
      } catch (error) {
        console.error("Errore nel recupero delle notizie:", error);
      }
    };
    fetchNews();
  }, []);

  useEffect(() => {
    const heights = cardsRef.current.map(card => card.clientHeight);
    const minHeight = Math.min(...heights);
    cardsRef.current.forEach(card => card.style.height = `${minHeight}px`);
  }, [newsItems]);

  return (
    <Container className="p-4 news-list-container wide-container" style={{ marginBottom: '20px' }}>
      <div className="header">
        <FaNewspaper className="icon" style={{ color: 'black',fontSize: '48px' }} />
        <h1 className="title" style={{ fontFamily: 'Inter, sans-serif', color: 'black', fontSize: '48px' }}>ULTIME NEWS!</h1>
      </div>
      <Row className="mt-4 flex-row flex-wrap">
        {newsItems.map((item, index) => (
          <Col md={4} className="mb-4 d-flex" key={index}>
            <Card
              ref={(el) => (cardsRef.current[index] = el)}
              className="h-100 animate-card flex-grow-1"
            >
              <Card.Body className="d-flex flex-column justify-content-center">
                <Card.Title className="card-title" style={{ fontFamily: 'Inter, sans-serif', color: 'white' }}>{item.title}</Card.Title>
                <Card.Text className="card-text"style={{ fontFamily: 'Inter, sans-serif', color: 'white'}}>{item.content}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <style jsx>{`
        .wide-container {
          max-width: 1200px;
        }
        .animate-card {
          transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
        }
        .animate-card:hover {
          transform: translateY(-10px);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        }
        .header {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px;
        }
        .icon {
          margin-right: 10px;
          font-size: 2rem;
        }
        .title {
          font-family: 'Inter, sans-serif';
          text-align: center;
          font-size: 2.5rem;
        }
        .card-body {
          display: flex;
          align-items: center;
          background-color: black;
          color: white;
          border-radius: 15px;
          border: 2px solid white;
        }
        .card-title,
        .card-text {
          font-family: 'Inter, sans-serif';
        }
        .card-title {
          font-weight: bold;
          font-size: 1.5rem;
        }
        .card-text {
          font-size: 1.2rem;
        }
        @media (min-width: 769px) {
          .card-body {
            display: flex;
            align-items: center;
          }
          .card-title,
          .card-text {
            margin-left: 20px;
          }
        }
      `}</style>
    </Container>
  );
};

export default NewsList;

























 


























