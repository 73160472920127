import React from 'react';
import { Container, Card, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbarngl from '../navbarngl';
import Tops from '../top';

const PrivacyPolicy = () => {
  return (
    <div>
      <Navbarngl />
      <Container className="mt-5">
        <Card>
          <Card.Header className="bg-primary text-white">
            <h2>Politica sulla Privacy - NGL Cila</h2>
          </Card.Header>
          <Card.Body>
            <Card.Title>Introduzione</Card.Title>
            <Card.Text>
              Su NGL Cila, prendiamo molto sul serio la tua privacy. Questa politica descrive come raccogliamo, utilizziamo e proteggiamo i tuoi dati personali in conformità con il GDPR.
            </Card.Text>
            <Card.Title>Raccolta dei Dati</Card.Title>
            <Card.Text>
              Raccogliamo i dati personali che ci fornisci direttamente attraverso il nostro sito web. Questo include informazioni come il tuo nome, indirizzo email e qualsiasi altro dettaglio che scegli di condividere.
            </Card.Text>
            <Card.Title>Conservazione dei Dati</Card.Title>
            <Card.Text>
              Tutti i dati personali sono conservati in modo sicuro sui nostri server. Garantiamo che i nostri server siano protetti con le ultime misure di sicurezza per prevenire accessi non autorizzati.
            </Card.Text>
            <Card.Title>Utilizzo dei Dati</Card.Title>
            <Card.Text>
              I tuoi dati personali vengono utilizzati esclusivamente per fornire e migliorare i nostri servizi. Non condividiamo i tuoi dati con terze parti senza il tuo esplicito consenso.
            </Card.Text>
            <Card.Title>Protezione dei Dati</Card.Title>
            <Card.Text>
              Siamo impegnati a proteggere i tuoi dati personali. Le nostre misure di sicurezza includono crittografia, firewall e audit di sicurezza regolari per garantire che i tuoi dati rimangano al sicuro.
            </Card.Text>
            <Card.Title>I Tuoi Diritti</Card.Title>
            <Card.Text>
              In base al GDPR, hai il diritto di accedere, rettificare o cancellare i tuoi dati personali. Se hai domande o richieste riguardanti i tuoi dati, ti preghiamo di contattarci.
            </Card.Text>
          </Card.Body>
        </Card>
      </Container>
      <div className="mt-4">
        <Tops />
      </div>
    </div>
  );
};

export default PrivacyPolicy;



